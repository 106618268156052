<template>
  <div>
    <v-card elevation="0" class="px-5 py-5">
      <v-row>
        <v-col md="2">
          <v-text-field color="#757575" :disabled="disableDebit" :label="$t('debtor')" v-model="filterData.debit" 
            type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field color="#757575" :disabled="disableCredit" :label="$t('credit')" v-model="filterData.credit"
            type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field color="#757575" :label="$t('explain')" v-model="filterData.desc"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-autocomplete :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name"
            item-value="id" :loading="costCenterItemsLoading" v-model="filterData.costCenter"
            @keypress="fetchAutoCompleteCostCenter" no-filter clearable>
          </v-autocomplete>
        </v-col>
        <v-col md="1" class="d-flex justify-end align-center">
          <v-icon @click="filterTable">mdi-magnify</v-icon>
        </v-col>
        <v-col md="2" class="py-5">
          <v-btn color="blue" dark block  @click="exportToExcel(filterData)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
export default {
  data() {
    return {
      filterData: {
        debit: null,
        credit: null,
        desc: "",
        costCenter: null,
        page: 1,
      },
      debit: null,
      credit: null,
      costCenterItems: [],
      costCenterItemsLoading: false,
    };
  },
  computed: {
    disableCredit() {
      return !!this.filterData.debit;
    },
    disableDebit() {
      return !!this.filterData.credit;
    },
  },
  methods: {
    filterTable() {
      this.$emit("filterTable", this.filterData);
    },
    async exportToExcel(queryParams){
          
          try {
      
      const excel = await axios.get(`/accounting/account/statement/export/${this.$route.params.statementId}`, {
        params: {
          ...queryParams,
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      

    } catch (err) {
      console.log('err', err);
    } finally {
      
    }
     
    },
    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterItemsLoading = false;
        }
      }, 500)();
    },
  },
};
</script>

<style>

</style>